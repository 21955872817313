import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import style from "../css/header.module.css"

const Header = ({ siteTitle }) => (
  <header className={style.header}>
    <div
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: 100,
      }}
    >
      <Link
        to="/"
        style={{
          color: `#333`,
          textDecoration: `none`,
          flex: 1,
          textAlign: "center",
        }}
      >
        <p className={style.siteTitle}>{siteTitle}</p>
      </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
