import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Terms = () => (
  <Layout>
    <SEO title="Terms of Service" />
    <div style={{ marginLeft: 50, marginTop: 50, maxWidth: 960 }}>
      <p>
        <strong>
          <span>END USER LICENSE AGREEMENT - VIBE APP</span>
        </strong>
      </p>
      <p>
        <span>
          We&rsquo;ve drafted these Terms of Service (which we call the
          &ldquo;Terms&rdquo;) so you&rsquo;ll know the rules that govern our
          relationship with you. These Terms form a legally binding contract
          between you and&nbsp;
        </span>
        <span>Queue ApS</span>
        <span>&nbsp;So please read them carefully.</span>
      </p>
      <p>
        <span>By</span>
        <span>&nbsp;using our application VIBE&nbsp;</span>
        <span>(we refer&nbsp;</span>
        <span>to this&nbsp;</span>
        <span>collectively as the &ldquo;</span>
        <span>Application</span>
        <span>
          &rdquo;), you agree to the Terms. Of course, if you don&rsquo;t agree
          with them, then don&rsquo;t use the Application.
        </span>
        <span>
          &nbsp;By downloading the Application from the Apple AppStore, You
          indicate that You agree to be bound by all of the terms and conditions
          of this License Agreement, and that you Accept this License
          Agreement.&nbsp;
        </span>
      </p>
      <p>
        <span>
          The parties of this License Agreement acknowledge that Apple is not a
          Party to this License Agreement and is not bound by any provisions or
          obligations with regard to the Application, such as warranty,
          liability, maintenance and support thereof. Queue ApS is solely
          responsible for the licensed Application and the content
          thereof.&nbsp;
        </span>
      </p>
      <p>
        <strong>
          <span>1. Who Can Use the Application</span>
        </strong>
      </p>
      <p>
        <span>
          No one under 13 is allowed to create an account or use the Application
        </span>
      </p>
      <p>
        <span>By using the Application, you state that:</span>
      </p>
      <ul>
        <li>
          <span>You can form a binding contract with&nbsp;</span>
          <span>Queue ApS</span>
          <span>.</span>
        </li>
        <li>
          <span>
            You are not a person who is barred from receiving the Application
            under the laws of the United States or any other applicable
            jurisdiction&mdash;including, for example, that you do not appear on
            the U.S. Treasury Department&rsquo;s list of Specially Designated
            Nationals or face any other similar prohibition.
          </span>
        </li>
        <li>
          <span>You are not a convicted sex offender.</span>
        </li>
        <li>
          <span>
            You will comply with these Terms and all applicable local, state,
            national, and international laws, rules, and regulations.
          </span>
        </li>
      </ul>
      <p>
        <span>
          If you are using the Application on behalf of a business or some other
          entity, you state that you are authorized to grant all licenses set
          forth in these Terms and to agree to these Terms on behalf of the
          business or entity.&nbsp;
        </span>
      </p>
      <p>
        <strong>
          <span>2. Rights We Grant You</span>
        </strong>
      </p>
      <p>
        <span>Queue ApS</span>
        <span>
          &nbsp;grants you a personal, worldwide, royalty-free, non-assignable,
          nonexclusive, revocable, and non-sublicensable license to access and
          use the Application. This license is for the sole purpose of letting
          you use and enjoy the Application&rsquo; benefits in a way that these
          Terms and our usage policies
        </span>
        <span>&nbsp;</span>
        <span>allow.</span>
      </p>
      <p>
        <span>
          Any software that we provide you may automatically download and
          install upgrades, updates, or other new features. You may be able to
          adjust these automatic downloads through your device&rsquo;s settings.
        </span>
      </p>
      <p>
        <span>
          You may not copy, modify, distribute, sell, or lease any part of our
          Application, nor may you reverse engineer or attempt to extract the
          source code of that software, unless laws prohibit these restrictions
          or you have our written permission to do so.
        </span>
      </p>
      <p>
        <strong>
          <span>3. Rights You Grant Us</span>
        </strong>
      </p>
      <p>
        <span>
          Many of our Application let you create, upload, post, send, receive,
          and store content. When you do that, you retain whatever ownership
          rights in that content you had to begin with. But you grant us a
          license to use that content. How broad that license is depends on
          which Application you use and the Settings you have selected.
        </span>
      </p>
      <p>
        <span>
          We call Story submissions that are set to be viewable by Everyone as
          well as content you submit to crowd-sourced Application, including Our
          Story, &ldquo;Public Content.&rdquo; For all content you submit to the
          Application other than Public Content, you grant&nbsp;
        </span>
        <span>Queue ApS</span>
        <span>&nbsp;</span>
        <span>
          and our affiliates a worldwide, royalty-free, sublicensable, and
          transferable license to host, store, use, display, reproduce, modify,
          adapt, edit, publish, and distribute that content. This license is for
          the limited purpose of operating, developing, providing, promoting,
          and improving the Application and researching and developing new ones.
        </span>
      </p>
      <p>
        <span>
          Because Public Content is inherently public and chronicles matters of
          public interest, the license you grant us for this content is broader.
          For Public Content, you grant&nbsp;
        </span>
        <span>Queue ApS</span>
        <span>
          , our affiliates, and our business partners all of the same rights you
          grant for non-Public Content in the previous paragraph, as well as a
          perpetual license to create derivative works from, promote, exhibit,
          broadcast, syndicate, publicly perform, and publicly display Public
          Content in any form and in any and all media or distribution methods
          (now known or later developed). To the extent it&rsquo;s necessary,
          when you appear in, create, upload, post, or send Public Content, you
          also grant
        </span>
        <span>&nbsp;Queue ApS</span>
        <span>.</span>
        <span>
          , our affiliates, and our business partners the unrestricted,
          worldwide, perpetual right and license to use your name, likeness, and
          voice, including in connection with commercial or sponsored content.
          This means, among other things, that you will not be entitled to any
          compensation from&nbsp;
        </span>
        <span>Queue ApS</span>
        <span>.</span>
        <span>
          , our affiliates, or our business partners if your name, likeness, or
          voice is conveyed through the Application, either on the Snapchat
          application or on one of our business partner&rsquo;s platforms.
        </span>
      </p>
      <p>
        <span>
          For information about how to tailor who can watch your content, please
          take a look at our{" "}
          <a href="https://join-vibe-app.com/privacy">
            <span>Privacy Policy</span>
          </a>
          &nbsp;
        </span>
        <span>(https://join-vibe-app.com/privacy)</span>
        <span>.</span>
      </p>
      <p>
        <span>
          While we&rsquo;re not required to do so, we may access, review,
          screen, and delete your content at any time and for any reason,
          including to provide and develop the Application or if we think your
          content violates these Terms. You alone, though, remain responsible
          for the content you create, upload, post, send, or store through the
          Service.
        </span>
      </p>
      <p>
        <span>
          The Application may contain advertisements. In consideration for&nbsp;
        </span>
        <span>Queue ApS</span>
        <span>.</span>
        <span>
          &nbsp;letting you access and use the Application, you agree that we,
          our affiliates, and our third-party partners may place advertising on
          the Application. Because the Application contain content that you and
          other users provide us, advertising may sometimes appear near your
          content.
        </span>
      </p>
      <p>
        <span>
          We always love to hear from our users. But if you provide feedback or
          suggestions, just know that we can use them without compensating you,
          and without any restriction or obligation to you.
        </span>
      </p>
      <p>
        <strong>
          <span>4. The Content of Others</span>
        </strong>
      </p>
      <p>
        <span>
          Much of the content on our Application is produced by users,
          publishers, and other third parties. Whether that content is posted
          publicly or sent privately, the content is the sole responsibility of
          the person or organization that submitted it. Although&nbsp;
        </span>
        <span>Queue ApS</span>
        <span>
          &nbsp;reserves the right to review or remove all content that appears
          on the Application, we do not necessarily review all of it. So we
          cannot&mdash;and do not&mdash;take responsibility for any content that
          others provide through the Application.
        </span>
      </p>
      <p>
        <span>
          Through these Terms, we make clear that we do not want the Application
          put to bad uses. But because we do not review all content, we cannot
          guarantee that content on the Application, or that our users&rsquo;
          use of our Application, will always conform to our Terms or
          Guidelines.
        </span>
      </p>
      <p>
        <strong>
          <span>5. Privacy</span>
        </strong>
      </p>
      <p>
        <span>
          Your privacy matters to us. You can learn how we handle your
          information when you use our Application by reading our{" "}
          <a href="https://join-vibe-app.com/privacy">
            <span>Privacy Policy</span>
          </a>
          . We encourage you to give the Privacy Policy a careful look because,
          by using our Application, you agree that&nbsp;
        </span>
        <span>Queue ApS</span>
        <span>.</span>
        <span>
          &nbsp;can collect, use, and share your information consistent with
          that policy.
        </span>
      </p>
      <p>
        <strong>
          <span>6. Respecting Other People&rsquo;s Rights</span>
        </strong>
      </p>
      <p>
        <span>Queue ApS</span>
        <span>
          &nbsp;respects the rights of others. And so should you. You therefore
          may not use the Application, or enable anyone else to use the
          Application, in a manner that:
        </span>
      </p>
      <ul>
        <li>
          <span>
            violates or infringes someone else&rsquo;s rights of publicity,
            privacy, copyright, trademark, or other intellectual property right.
          </span>
        </li>
        <li>
          <span>bullies, harasses, or intimidates.</span>
        </li>
        <li>
          <span>defames.</span>
        </li>
        <li>
          <span>spams or solicits our users.</span>
        </li>
      </ul>
      <p>
        <span>
          In short: You may not use the Application or the content on the
          Application in ways that are not authorized by these Terms. Nor may
          you help anyone else in doing so.
        </span>
      </p>
      <p>
        <strong>
          <span>7. Respecting Copyright</span>
        </strong>
      </p>
      <p>
        <span>Queue ApS</span>
        <span>.</span>
        <span>
          &nbsp;honors copyright laws, including the Digital Millennium
          Copyright Act. We therefore take reasonable steps to expeditiously
          remove from our Application any infringing material that we become
          aware of. And if&nbsp;
        </span>
        <span>Queue ApS</span>
        <span>
          &nbsp;becomes aware that one of its users has repeatedly infringed
          copyrights, we will take reasonable steps within our power to
          terminate the user&rsquo;s account.
        </span>
      </p>
      <p>
        <span>
          We make it easy for you to report suspected copyright infringement. If
          you believe that anything on the Application infringes a copyright
          that you own or control, please
        </span>
        <span>&nbsp;by contacting us</span>
        <span>.&nbsp;</span>
      </p>
      <p>
        <strong>
          <span>8. Safety</span>
        </strong>
      </p>
      <p>
        <span>
          We try hard to keep our Application a safe place for all users. But we
          can&rsquo;t guarantee it. That&rsquo;s where you come in. By using the
          Application, you agree that:
        </span>
      </p>
      <ul>
        <li>
          <span>
            You will not use the Application for any purpose that is illegal or
            prohibited in these Terms.
          </span>
        </li>
        <li>
          <span>
            You will not use any robot, spider, crawler, scraper, or other
            automated means or interface to access the Application or extract
            other user&rsquo;s information.
          </span>
        </li>
        <li>
          <span>
            You will not use or develop any third-party applications that
            interact with the Application or other users&rsquo; content or
            information without our written consent.
          </span>
        </li>
        <li>
          <span>
            You will not use the Application in a way that could interfere with,
            disrupt, negatively affect, or inhibit other users from fully
            enjoying the Application, or that could damage, disable, overburden,
            or impair the functioning of the Application.
          </span>
        </li>
        <li>
          <span>
            You will not use or attempt to use another user&rsquo;s account,
            username, or password without their permission.
          </span>
        </li>
        <li>
          <span>You will not solicit login credentials from another user.</span>
        </li>
        <li>
          <span>
            You will not post content that contains or links to pornography,
            graphic violence, threats, hate speech, or incitements to violence.
          </span>
        </li>
        <li>
          <span>
            You will not upload viruses or other malicious code or otherwise
            compromise the security of the Application.
          </span>
        </li>
        <li>
          <span>
            You will not attempt to circumvent any content-filtering techniques
            we employ, or attempt to access areas or features of the Application
            that you are not authorized to access.
          </span>
        </li>
        <li>
          <span>
            You will not probe, scan, or test the vulnerability of our
            Application or any system or network.
          </span>
        </li>
        <li>
          <span>
            You will not encourage or promote any activity that violates these
            Terms.
          </span>
        </li>
      </ul>
      <p>
        <span>
          We also care about your safety while using our Application. So do not
          use our Application in a way that would distract you from obeying
          traffic or safety laws. For example, never&nbsp;
        </span>
        <span>VIBE</span>
        <span>
          &nbsp;and drive. And never put yourself or others in harm&rsquo;s way
          just to capture a&nbsp;
        </span>
        <span>Story</span>
        <span>.</span>
      </p>
      <p>
        <strong>
          <span>9. Your Account</span>
        </strong>
      </p>
      <p>
        <span>You are responsible for any activity that occurs in&nbsp;</span>
        <span>your VIBE&nbsp;</span>
        <span>
          account. So it&rsquo;s important that you keep your account secure.
          One way to do that is to select a strong password that you don&rsquo;t
          use for any other account.
        </span>
      </p>
      <p>
        <span>
          By using the Application, you agree that, in addition to exercising
          common sense:
        </span>
      </p>
      <ul>
        <li>
          <span>You will not create more than one account for yourself.</span>
        </li>
        <li>
          <span>
            You will not create another account if we have already disabled your
            account, unless you have our written permission to do so.
          </span>
        </li>
        <li>
          <span>
            You will not buy, sell, rent, or lease access to your Snapchat
            account, Snaps, a Snapchat username, or a friend link without our
            written permission.
          </span>
        </li>
        <li>
          <span>You will not share your password.</span>
        </li>
        <li>
          <span>
            You will not log in or attempt to access the Application through
            unauthorized third-party applications or clients.
          </span>
        </li>
      </ul>
      <p>
        <span>
          If you think that someone has gained access to your account, please
          immediately reach out to
        </span>
        <span>&nbsp;us.</span>
      </p>
      <p>
        <strong>
          <span>11. Data Charges and Mobile Phones</span>
        </strong>
      </p>
      <p>
        <span>
          You are responsible for any mobile charges that you may incur for
          using our Application, including text-messaging and data charges. If
          you&rsquo;re unsure what those charges may be, you should ask your
          service provider before using the Application.
        </span>
      </p>
      <p>
        <span>
          If you change or deactivate the mobile phone number that you used to
          create a&nbsp;
        </span>
        <span>VIBE</span>
        <span>
          &nbsp;account, you must update your account information through
          Settings within 72 hours to prevent us from sending to someone else
          messages intended for you.
        </span>
      </p>
      <p>
        <strong>
          <span>12. Third-Party Application</span>
        </strong>
      </p>
      <p>
        <span>
          If you use a service, feature, or functionality that is operated by a
          third party and made available through our Application (including
          Application we jointly offer with the third party), each party&rsquo;s
          terms will govern the respective party&rsquo;s relationship with
          you.&nbsp;
        </span>
        <span>Queue ApS</span>
        <span>.</span>
        <span>
          &nbsp;is not responsible or liable for a third party&rsquo;s terms or
          actions taken under the third party&rsquo;s terms.
        </span>
      </p>
      <p>
        <strong>
          <span>15. Disclaimers</span>
        </strong>
      </p>
      <p>
        <span>
          We try to keep the Application up and running and free of annoyances.
          But we make no promises that we will succeed.
        </span>
      </p>
      <p>
        <span>
          THE APPLICATION ARE PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;AS
          AVAILABLE&rdquo; AND TO THE EXTENT PERMITTED BY LAW WITHOUT WARRANTIES
          OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
          IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, TITLE, AND NON-INFRINGEMENT. IN ADDITION, WHILE{" "}
          <span>Queue ApS</span> ATTEMPTS TO PROVIDE A GOOD USER EXPERIENCE, WE
          DO NOT REPRESENT OR WARRANT THAT: (A) THE APPLICATION WILL ALWAYS BE
          SECURE, ERROR-FREE, OR TIMELY; (B) THE APPLICATION WILL ALWAYS
          FUNCTION WITHOUT DELAYS, DISRUPTIONS, OR IMPERFECTIONS; OR (C) THAT
          ANY CONTENT, USER CONTENT, OR INFORMATION YOU OBTAIN ON OR THROUGH THE
          APPLICATION WILL BE TIMELY OR ACCURATE.
        </span>
      </p>
      <p>
        <span>Queue ApS</span>
        <span>.</span>
        <span>
          &nbsp;TAKES NO RESPONSIBILITY AND ASSUMES NO LIABILITY FOR ANY CONTENT
          THAT YOU, ANOTHER USER, OR A THIRD PARTY CREATES, UPLOADS, POSTS,
          SENDS, RECEIVES, OR STORES ON OR THROUGH OUR APPLICATION. YOU
          UNDERSTAND AND AGREE THAT YOU MAY BE EXPOSED TO CONTENT THAT MIGHT BE
          OFFENSIVE, ILLEGAL, MISLEADING, OR OTHERWISE INAPPROPRIATE, NONE OF
          WHICH&nbsp;
        </span>
        <span>Queue ApS</span>
        <span>.</span>
        <span>&nbsp;WILL BE RESPONSIBLE FOR.</span>
      </p>
      <p>
        <strong>
          <span>16. Limitation of Liability</span>
        </strong>
      </p>
      <p>
        <span>TO THE MAXIMUM EXTENT PERMITTED BY LAW,&nbsp;</span>
        <span>Queue ApS</span>
        <span>.</span>
        <span>
          &nbsp;AND OUR MANAGING MEMBERS, SHAREHOLDERS, EMPLOYEES, AFFILIATES,
          LICENSORS, AGENTS, AND SUPPLIERS WILL NOT BE LIABLE FOR ANY INDIRECT,
          INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR MULTIPLE DAMAGES, OR
          ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR
          INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE
          LOSSES, RESULTING FROM: (A) YOUR ACCESS TO OR USE OF OR INABILITY TO
          ACCESS OR USE THE APPLICATION; (B) THE CONDUCT OR CONTENT OF OTHER
          USERS OR THIRD PARTIES ON OR THROUGH THE APPLICATION; OR (C)
          UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR CONTENT, EVEN IF{" "}
          <span>Queue ApS.</span> HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES. IN NO EVENT WILL <span>Queue ApS.</span>
          &rsquo;S AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE
          APPLICATION EXCEED THE GREATER OF $100 USD OR THE AMOUNT YOU
          PAID&nbsp;
        </span>
        <span>Queue ApS</span>
        <span>&nbsp;</span>
        <span>IF ANY, IN THE LAST 12 MONTHS.</span>
      </p>
      <p>
        <strong>
          <span>18</span>
        </strong>
        <strong>
          <span>. Final Terms</span>
        </strong>
      </p>
      <ul>
        <li>
          <span>
            These Terms (together with any additional terms applicable to
            specific Application you use) make up the entire agreement between
            you and <span>Queue ApS</span>, and supersede any prior agreements.
          </span>
        </li>
        <li>
          <span>
            These Terms do not create or confer any third-party beneficiary
            rights.
          </span>
        </li>
        <li>
          <span>
            If we do not enforce a provision in these Terms, it will not be
            considered a waiver.
          </span>
        </li>
        <li>
          <span>We reserve all rights not expressly granted to you.</span>
        </li>
        <li>
          <span>
            You may not transfer any of your rights or obligations under these
            Terms without our consent.
          </span>
        </li>
      </ul>
      <p>
        <strong>
          <span>Contact Us</span>
        </strong>
      </p>
      <p>
        <span>Queue ApS</span>
        <span>
          &nbsp;welcomes comments, questions, concerns, or suggestions. Please
          contact us&nbsp;
        </span>
        <span>at: anker@queue-app.com</span>
      </p>
    </div>
  </Layout>
)

export default Terms
